var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "角色名称", prop: "roleName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.roleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "roleName", $$v)
                  },
                  expression: "form.roleName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "权限标识", prop: "roleKey" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.roleKey,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "roleKey", $$v)
                  },
                  expression: "form.roleKey",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "排序", prop: "roleSort" } },
            [
              _c("a-input-number", {
                staticStyle: { width: "100%" },
                attrs: { placeholder: "请输入", min: 0 },
                model: {
                  value: _vm.form.roleSort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "roleSort", $$v)
                  },
                  expression: "form.roleSort",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "菜单权限" } },
            [
              _c(
                "a-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.handleCheckedTreeExpand($event)
                    },
                  },
                },
                [_vm._v(" 展开/折叠 ")]
              ),
              _c(
                "a-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.handleCheckedTreeNodeAll($event)
                    },
                  },
                },
                [_vm._v(" 全选/全不选 ")]
              ),
              _c(
                "a-checkbox",
                {
                  attrs: { checked: _vm.form.menuCheckStrictly },
                  on: {
                    change: function ($event) {
                      return _vm.handleCheckedTreeConnect($event)
                    },
                  },
                },
                [_vm._v(" 父子联动 ")]
              ),
              _c("a-tree", {
                attrs: {
                  checkable: "",
                  checkStrictly: !_vm.form.menuCheckStrictly,
                  "expanded-keys": _vm.menuExpandedKeys,
                  "auto-expand-parent": _vm.autoExpandParent,
                  "tree-data": _vm.menuOptions,
                  replaceFields: _vm.defaultProps,
                },
                on: { check: _vm.onCheck, expand: _vm.onExpandMenu },
                model: {
                  value: _vm.menuCheckedKeys,
                  callback: function ($$v) {
                    _vm.menuCheckedKeys = $$v
                  },
                  expression: "menuCheckedKeys",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入",
                  type: "textarea",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }